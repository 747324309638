import(/* webpackMode: "eager", webpackExports: ["SecureCookiesProvider"] */ "/opt/build/repo/packages/adoptparfumsb2b/frontend/node_modules/next-client-cookies/dist/provider.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/adoptparfumsb2b/frontend/node_modules/next/font/google/target.css?{\"path\":\"src/fonts/index.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-inter\",\"display\":\"swap\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/adoptparfumsb2b/frontend/node_modules/next/font/google/target.css?{\"path\":\"src/fonts/index.tsx\",\"import\":\"DM_Serif_Display\",\"arguments\":[{\"weight\":\"400\",\"variable\":\"--font-serif\",\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"serifDisplay\"}");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/adoptparfumsb2b/frontend/node_modules/next/font/google/target.css?{\"path\":\"src/fonts/index.tsx\",\"import\":\"Manrope\",\"arguments\":[{\"weight\":\"800\",\"variable\":\"--font-manrope\",\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"manrope\"}");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/adoptparfumsb2b/frontend/node_modules/next/font/local/target.css?{\"path\":\"src/fonts/index.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./gotham/Gotham-Black.otf\",\"weight\":\"800\",\"style\":\"normal\"},{\"path\":\"./gotham/Gotham-Bold.otf\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"./gotham/Gotham-Book.otf\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"./gotham/Gotham-BookItalic.otf\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"./gotham/Gotham-Light.otf\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"./gotham/Gotham-Medium.otf\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"./gotham/Gotham-Thin.otf\",\"weight\":\"100\",\"style\":\"normal\"},{\"path\":\"./gotham/Gotham-ThinItalic.otf\",\"weight\":\"100\",\"style\":\"italic\"},{\"path\":\"./gotham/Gotham-UltraItalic.otf\",\"weight\":\"900\",\"style\":\"italic\"},{\"path\":\"./gotham/Gotham-XLightItalic.otf\",\"weight\":\"200\",\"style\":\"italic\"}],\"variable\":\"--font-gotham\"}],\"variableName\":\"gotham\"}");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/adoptparfumsb2b/frontend/node_modules/react-loading-skeleton/dist/skeleton.css");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/adoptparfumsb2b/frontend/node_modules/react-tooltip/dist/react-tooltip.min.css");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/adoptparfumsb2b/frontend/node_modules/flag-icons/css/flag-icons.min.css");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/packages/adoptparfumsb2b/frontend/src/styles/global/index.css");
